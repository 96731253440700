<template>
  <div>
     <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-select2
        variant="outline-primary"
      >
        Ajouter une permission
      </b-button>
    </div>

    <b-modal
      id="modal-select2"
      title="Ajouter une permission"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      centered
      @ok="savePermission"
    >
      <b-form>
        <b-form-group
          label="Titre"
          label-for="titre"
        >
          <b-form-input
            id="titre"
            v-model="title"
            placeholder="Entrer le titre"
            invalid-feedback="Le titre est requis"
            required
          />
        </b-form-group>
        <b-form-group
          label="Choississez les roles"
          label-for="vue-select"
        >
          <v-select
            id="vue-select"
            v-model="selectedRoles"
            :options="allRoles"
            multiple
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions } from "vuex";

// store module and vuex utilities
import permissionsStroreModule from "@/store/permissions";
import paramsStoreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "permissions", module: permissionsStroreModule },
      { path: "params", module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules};
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },  
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      title: "",
      selectedRoles: [],
      allRoles: [],
    }
  },
  mounted() {
    this.fetchRoles().then(response => this.allRoles = response.data.data.map(role => role.title))
    console.log(this.allRoles);
  },
  methods: {
     ...mapActions("permissions", {
            action_addPermissions: "addPermissions",
        }),
     ...mapActions("params", {
            fetchRoles: "fetchRoles",
        }),
      savePermission() {
        console.log('submitted')
        console.log(this.title)
        console.log(this.selectedRoles)

        const permission = { title: this.title, roles: this.selectedRoles };
       this.action_addPermissions()
        .then(response => {
            this.permissionId = response.data.id
        })
        .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
        });
      }
  },
}
</script>
